import { pmt } from 'financial';
class FinancialService {

  public static annualToPeriodicInterestRate(tea) {
    // Convertir la tasa efectiva anual a tasa periódica mensual
    const periodic_rate = Math.pow(1 + tea, 1/12) - 1;

    return periodic_rate;
  }
  public static calculate_installment_amount(price, tea, installments) {
    const r = FinancialService.annualToPeriodicInterestRate(tea);
    const cuota = pmt(r, installments, -price);

    return cuota;
  }
}

export default FinancialService;

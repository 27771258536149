
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Order } from "@/core/model/Orders";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { displayErrors } from "@/core/helpers/errors";
import ToastService from "@/core/services/ToastService";
import { hideModal } from "@/core/helpers/dom";
import type { MutualProduct } from "@/core/model/mutualProduct";
import type { CartItem } from "@/core/model/CartItem";
import type { PaymentPlan, IPaymentPlan } from "@/core/model/PaymentPlan";
import UtilsService from "@/core/services/UtilsService";
import type { FormInstance, FormRules } from "element-plus";
import FinancialService from "@/core/services/FinancialService";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

interface SummaryMethodProps<T = MutualProduct> {
  columns: [];
  data: T[];
}
interface IFormData {
  order: string;
  payment_plan: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  comments: string;
  products: Array<CartItem>;
}
export default defineComponent({
  name: "order-form",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref<FormInstance>();
    const loadingOrder = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const loadingProducts = ref<boolean>(false);
    const loadingPlans = ref<boolean>(false);
    const rowsPerPage = ref(10);
    const pageSizes = ref<Array<number>>([10, 20, 30, 50, 100]);
    const currentPage = ref(1);
    const total = ref(0);
    let sortOrder = "asc";
    let sortField = "name";
    const tableProductsData = ref<Array<MutualProduct>>([]);
    const paymentPlans = ref<Array<PaymentPlan>>([]);
    const utils = UtilsService;
    const order = ref<Order>({} as Order);
    const formData = ref<IFormData>({
      order: "",
      payment_plan: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      comments: "",
      products: [],
    });
    const rules = ref<FormRules>({
      firstname: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
      ],
      lastname: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
      ],
      email: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
        {
          validator: (rule, value, callback) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              callback(
                new Error("Por favor, ingrese un correo electrónico válido.")
              );
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      phone: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
        {
          max: 15,
          message: "Este campo admite máximo 15 dígitos",
          trigger: "blur",
        },
        {
          min: 7,
          message: "Este campo admite mínimo 7 dígitos",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            const phoneRegex = /^[0-9]*$/;
            if (!phoneRegex.test(value)) {
              callback(
                new Error("Este campo sólo acepta números, sin espacios.")
              );
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      payment_plan: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
      ],
      products: [
        { required: true, message: "Este campo es requerido", trigger: "blur" },
        {
          validator: (rule, value, callback) => {
            console.log("validator prods", value);
            if (value.length === 0) {
              callback(new Error("Debe agregar al menos un producto."));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Solicitudes de crédito", [
        "Órdenes",
        isEdit() ? "Editar" : "Nuevo",
      ]);
      if(isEdit()) {
        formData.value.order = String(route.params.uuid);
      }
      loadPaymentPlans();
      loadProducts();
    });

    const isEdit = () => {
      return route.params.uuid !== undefined;
    };

    const submit = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (
          fields === undefined &&
          (formData.value.products.length === 0 ||
            formData.value.payment_plan === "")
        ) {
          fields = {};
        }
        // Validar que products no esté vacío
        if (formData.value.products.length === 0) {
          valid = false;
          if (fields && !fields?.products) {
            fields.products = [];
          }
          fields?.products.push({
            message: "Debe agregar al menos un producto",
            field: "products",
          });
        }
        if (formData.value.payment_plan === "") {
          valid = false;
          if (fields && !fields?.payment_plan) {
            fields.payment_plan = [];
          }
          fields?.payment_plan.push({
            message: "Debe seleccionar un plan de pago",
            field: "payment_plan",
          });
        }

        if (valid) {
          if(isEdit()) {
            update();
          } else {
            create();
          }
        } else {
          const errorsToDisplay = [] as Array<string>;
          for (const fieldName in fields) {
            if (fieldName === "products" || fieldName === "payment_plan") {
              fields[fieldName].forEach((error) => {
                errorsToDisplay.push(String(error.message));
              });
            }
          }

          if (errorsToDisplay.length > 0) {
            const errorMessage = errorsToDisplay.join("<br>");
            ToastService.show(errorMessage, "error");
          }
        }
      });
    };

    const create = () => {
      loading.value = true;
      store
        .dispatch(Actions.ORDER_CREATE, formData.value)
        .then(() => {
          ToastService.show(
            "Solicitud de crédito creada con éxito",
            "success"
          );
          loading.value = false;
          router.push({
            name: "mutual_orders_detail",
            params: { uuid: store.state.OrdersModule.order.uuid },
          });
        })
        .catch(() => {
          displayErrors(store, "OrdersModule");
          loading.value = false;
        });
    };

    const update = () => {
      loading.value = true;
      store
        .dispatch(Actions.ORDER_UPDATE, formData.value)
        .then(() => {
          ToastService.show(
            "Solicitud de crédito actualizada con éxito",
            "success"
          );
          loading.value = false;
          router.push({
            name: "mutual_orders_detail",
            params: { uuid: store.state.OrdersModule.order.uuid },
          });
        })
        .catch(() => {
          displayErrors(store, "OrdersModule");
          loading.value = false;
        });
    };

    const loadOrder = () => {
      loadingOrder.value = true;
      store
        .dispatch(Actions.ORDER_GET, route.params.uuid)
        .then(() => {
          order.value = store.state.OrdersModule.order;
          order.value.items.forEach((item) => {
            item.product_subtotal = item.product_price * item.product_quantity;
          });
          loadOrderData();
        })
        .catch(() => {
          displayErrors(store, "OrdersModule");
        })
        .finally(() => {
          loadingOrder.value = false;
        });
    };

    const loadOrderData = () => {
      formData.value.firstname = order.value.customer_firstname;
      formData.value.lastname = order.value.customer_lastname;
      formData.value.email = order.value.customer_email;
      formData.value.phone = order.value.customer_phone;
      formData.value.comments = order.value.notes;
      order.value.items.forEach((item) => {
        formData.value.products.push({
          product_id: item.product_reference,
          product_name: item.product_name,
          price: item.product_quantity * item.product_price,
          quantity: item.product_quantity,
        });
      });
      recalculate_installment_amount();
      formData.value.payment_plan = order.value.payment_plan.uuid;
    };

    const loadProducts = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
      };
      loadingProducts.value = true;
      store
        .dispatch(Actions.PRODUCT_LIST, params)
        .then(() => {
          tableProductsData.value.splice(
            0,
            tableProductsData.value.length,
            ...store.state.ProductsModule.list.items
          );
          loadingProducts.value = false;
          rowsPerPage.value = store.state.ProductsModule.list.limit;
          currentPage.value = store.state.ProductsModule.list.page;
          total.value = store.state.ProductsModule.list.total;
        })
        .catch(() => {
          displayErrors(store, "ProductsModule");
          loadingProducts.value = false;
        });
    };

    const loadPaymentPlans = () => {
      loadingOrder.value = true;
      const params = {
        page_number: 1,
        page_size: 100,
        sort_order: "asc",
        sort_field: "name",
        search: "",
      };
      loadingPlans.value = true;
      store
        .dispatch(Actions.PAYMENT_PLANS_LIST, params)
        .then(() => {
          paymentPlans.value = store.state.PaymentPlansModule.list.items;
          loadingPlans.value = false;
          if (isEdit()) {
            loadOrder();
          } else {
            loadingOrder.value = false;
          }
        })
        .catch(() => {
          displayErrors(store, "PaymentPlansModule");
          loadingPlans.value = false;
          loadingOrder.value = false;
        });
    };

    const changePagination = (page) => {
      currentPage.value = page;
      loadProducts();
    };

    const pageSizeChange = (size) => {
      rowsPerPage.value = size;
      loadProducts();
    };

    const searchItems = () => {
      if (timerSearch) {
        clearTimeout(timerSearch);
        timerSearch = null;
      }
      timerSearch = setTimeout(() => {
        loadProducts();
      }, 800);
    };

    const getSummaryCart = (param: SummaryMethodProps) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "TOTAL";
          return;
        }
        if (data.length > 0) {
          const values = data.map((item) => Number(item[column["property"]]));
          if (!values.every((value) => Number.isNaN(value))) {
            sums[index] = utils.formatAmount(
              values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!Number.isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
            );
          } else {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    };

    const addToCart = (record: MutualProduct) => {
      const existingProductIndex = formData.value.products.findIndex(
        (product) => product.product_id === record.product.reference
      );

      if (existingProductIndex !== -1) {
        // Si ya existe el producto, incrementamos la cantidad
        formData.value.products[existingProductIndex].quantity += 1;
        formData.value.products[existingProductIndex].price =
          formData.value.products[existingProductIndex].quantity *
          record.base_price;
      } else {
        // Si no existe, agregamos un nuevo producto
        formData.value.products.push({
          product_id: record.product.reference,
          product_name: record.product.name,
          price: record.base_price,
          quantity: 1,
        });
      }
      recalculate_installment_amount();
    };

    const recalculate_installment_amount = () => {
      const total_cart: number = formData.value.products.reduce(
        (total: number, product: CartItem) => total * 1 + product.price * 1,
        0
      );
      paymentPlans.value.forEach((plan: IPaymentPlan) => {
        plan.installment_amount = FinancialService.calculate_installment_amount(
          total_cart,
          plan.interest_rate,
          plan.max_quotas
        );
      });
    };

    const removeToCart = (uuid: string) => {
      const existingProductIndex = formData.value.products.findIndex(
        (product) => product.product_id === uuid
      );

      if (existingProductIndex !== -1) {
        // Si ya existe el producto, incrementamos la cantidad
        formData.value.products.splice(existingProductIndex, 1);
      }
    };

    return {
      formData,
      formRef,
      rules,
      store,
      loading,
      loadingProducts,
      submit,
      tableProductsData,
      rowsPerPage,
      currentPage,
      total,
      pageSizes,
      changePagination,
      pageSizeChange,
      utils,
      search,
      searchItems,
      loadingPlans,
      loadPaymentPlans,
      paymentPlans,
      addToCart,
      getSummaryCart,
      removeToCart,
      loadingOrder,
      order,
      isEdit,
    };
  },
});
